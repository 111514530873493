import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import AddUser from "./pages/AddUser";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Questions from "./pages/Questions";
import Users from "./pages/Users";
import { login } from "./store/slices/adminSlice";

function App() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = JSON.parse(localStorage.getItem("admin"));

  useEffect(() => {
    if (data) {
      dispatch(login(data));
      // navigate("/");
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route path="/" element={<Users />} />
        <Route path="/adduser" element={<AddUser />} />
        <Route path="/questions/:id" element={<Questions />} />
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  )
}

export default App;
