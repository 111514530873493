import React, { useState } from 'react'
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../firebase/firebase';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../store/slices/adminSlice';
import { ThreeDots } from 'react-loader-spinner';
import './styles/login.css'

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)

    const loginUser = (e) => {
        setLoading(true)
        e.preventDefault();

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                console.log(userCredential.user)
                if (user) {
                    dispatch(login({ email: user.email, token: user.accessToken }));
                    navigate("/");
                }
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage)
                alert('Please Enter Your Correct Login Details')
            }).finally(() => {
                setLoading(false)
            });
    }

    if (loading) {
        return <div className='loader'>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#2D3748"
                ariaLabel="three-dots-loading"
                visible={true}
            />
        </div>
    }

    return (
        <div className="loginPage">
            <h2 className="loginHead">
                Sign in to your account
            </h2>
            <form className="loginForm" onSubmit={loginUser}>
                <input
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    required
                    className="loginInput"
                    placeholder="Email address"
                />
                <input
                    type="password"
                    required
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    className="loginInput"
                    placeholder="Password"
                />
                <button type="submit" className="loginBtn">
                    Sign In
                </button>
            </form>
        </div>
    )
}

export default Login
