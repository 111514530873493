import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase/firebase';
import './styles/addUser.css'
import { List, arrayMove } from 'react-movable';

const AddUser = () => {

  const navigate = useNavigate()
  const inputArr = [
    {
      id: 0,
      value: "",
    }
  ];

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [questions, setQuestions] = useState(inputArr)
  const [loading, setLoading] = useState(false)

  const handleChange = e => {
    e.preventDefault();

    const index = e.target.id;
    setQuestions(s => {
      const newArr = s.slice();
      newArr[index].value = e.target.value;

      return newArr;
    });
  };

  const addInput = () => {
    setQuestions(s => {
      return [
        ...s,
        {
          value: "",
          id: s[s.length - 1].id + 1
        }
      ];
    });
  };

  const removeInput = (id) => {
    const newArr = questions.filter((q => {
      return q.id !== id
    }))
    setQuestions(newArr)
  }

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {

      const user = await addDoc(collection(db, 'users'), {
        name,
        email,
        questions,
        link: '',
        isBlackAndWhite: false,
        id: '',
        response: '',
        retries: '',
        isffmpegProcessing: false,
        createdAt: 0
      });
      // let encodedData = Buffer.from(JSON.stringify(user.id), 'binary').toString('base64');

      const response = await fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_APP_LINK}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix: "https://interviewappissueplus.page.link",
            link: `https://issuemagazine.com?data=${user.id}`,
            androidInfo: {
              androidPackageName: "com.interviewappplus"
            },
            iosInfo: {
              "iosBundleId": "com.IssueInc.IssuePlusApps",
              "iosAppStoreId": "978466299"
            }
          }
        })
      })

      const data = await response.json();

      await updateDoc(doc(db, 'users', user.id), {
        link: data.shortLink,
        id: user.id,
        createdAt: new Date().getTime()
      })

      setEmail('')
      setName('')
      setQuestions(inputArr)
      navigate('/')
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const drop = async ({ oldIndex, newIndex }) => {
    setQuestions(arrayMove(questions, oldIndex, newIndex))
  };

  const splitStringAndSetQuestions = (questions) => {
    const splitQuestions = questions.split('\n')
    const newQuestions = splitQuestions.map((question, index) => {
      return {
        value: question,
        id: index
      }
    })
    setQuestions(newQuestions)
  }

  if (loading) {
    return <div className='loader'>
      <ThreeDots
        height="80"
        width="80"
        radius="9"
        color="#2D3748"
        ariaLabel="three-dots-loading"
        visible={true}
      />
    </div>
  }

  return (
    <form onSubmit={submit} className='form'>

      <div style={{ marginBottom: '3rem' }}>
        <label
          htmlFor="name"
          className='label'
        >User Name</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={e => setName(e.target.value)}
          required
          className='input'
        />
      </div>

      <div style={{ marginBottom: '3rem' }}>
        <label
          htmlFor="email"
          className='label'
        >User Email</label>
        <input
          type="email"
          id="email"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
          className='input'
        />
      </div>

      <div style={{ marginBottom: '3rem' }}>
        <label
          htmlFor="questionSplitter"
          className='label'
        >Questions Text</label>
        <textarea className="questionSplitter" 
          onChange={e => splitStringAndSetQuestions(e.target.value)} 
          placeholder="Enter Questions Here (one question per line)" />
      </div>

      <List
        values={questions}
        onChange={drop}
        renderList={({ children, props }) => {
          return <div style={{ marginBottom: '3rem' }} {...props}>{children}</div>
        }}
        renderItem={({ value, props, index }) => {
          return <div {...props} style={{ ...props.style, marginBottom: '3rem' }}>
            <label className='label'>Question {index + 1}</label>
            <div style={{ backgroundColor: 'white', display: 'flex' }}>
              <input
                type="text"
                required={index === 0}
                onChange={handleChange}
                value={value.value}
                id={index}
              />
              {index !== 0 && <button type='button' className='removeBtn' onClick={() => removeInput(value.id)}>Remove</button>}
            </div>
          </div>
        }}
      />

      {/* Add More Question Button */}
      <button onClick={addInput} type='button' className='addMoreBtn'>Add More Question</button>

      {/* Submit Button */}
      <button
        className='submitBtn'>
        Submit
      </button>
    </form>
  )
}

export default AddUser