import React from 'react'
import { useNavigate } from 'react-router-dom';
import './styles.css'

const Navbar = () => {
    const navigate = useNavigate()
    const logout = () => {
        localStorage.clear();
        navigate('/login')
    }

    return (
        <nav className='navContainer'>
            <div className='headWrapper' onClick={() => navigate('/')}>
                <h1 className="navHead">Interview Questions</h1>
            </div>
            <button className="navBtn" onClick={logout}>
                Log Out
            </button>
        </nav>
    )
}

export default Navbar