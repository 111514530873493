import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { List, arrayMove } from 'react-movable';
import { db } from '../firebase/firebase';
import './styles/questions.css'

const Questions = () => {
    const navigate = useNavigate()
    const { id } = useParams();

    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(false)
    const [copied, setCopied] = useState(false)
    const [questions, setQuestions] = useState([])
    const linkOfTheDocument = `${user.link}?data=${user.id}`;

    const fetchUser = async () => {
        setLoading(true)
        try {
            const data = await getDoc(doc(db, 'users', id));
            setUser({ ...data.data(), id: data.id })
            setQuestions(data.data().questions)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!id) {
            navigate('/')
        }
        fetchUser()
    }, [])

    const copyText = () => {
        setCopied(true)
        navigator.clipboard.writeText(linkOfTheDocument)
        setTimeout(() => {
            setCopied(false)
        }, 1500)
    }

    const drop = async ({ oldIndex, newIndex }) => {
        setQuestions(arrayMove(questions, oldIndex, newIndex))

        await updateDoc(doc(db, 'users', user.id), {
            questions: arrayMove(questions, oldIndex, newIndex)
        })
    };

    if (loading) {
        return <div className='loader'>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#2D3748"
                ariaLabel="three-dots-loading"
                visible={true}
            />
        </div>
    }

    return (
        <div className="page">
            {copied && <div
                className='copiedContainer'>
                <p className='copiedText'>Copied!</p>
            </div>}

            <div className='questionDetails'>
                <div>
                    <p className='questioneeDetails'>Name: {user.name}</p>
                    <p className='questioneeDetails'>Email: {user.email}</p>
                    <button className='questioneeDetails' onClick={copyText}>Link: {linkOfTheDocument}</button>
                </div>

                <QRCodeSVG value={linkOfTheDocument} size={220} className='qr' />
            </div>

            <h1 className='questionsHead'>
                Questions
            </h1>
            <div className='questionsWrapper'>
                <List
                    values={questions}
                    onChange={drop}
                    renderList={({ children, props }) => {
                        return <div className='questionsWrapper' {...props}>{children}</div>
                    }}
                    renderItem={({ value, props }) => {
                        return <div className='question'>
                            <p {...props}><strong>Q. </strong>{value.value}</p>
                        </div>
                    }}
                />
            </div>

            {user.response && <div className='questionsBtns'>
                <a className='viewResponseBtn' href={user.response} target='_blank' rel="noreferrer">View Response</a>
                <a className='viewResponseBtn' href={user.blackWhiteMerged} target='_blank' rel="noreferrer">View In B/W</a>
                {user.retries && <a className='retryBtn' href={user.retries} target='_blank' rel="noreferrer">View Retries</a>}
            </div>}
        </div>
    )
}
export default Questions
